bach-dialog {
	display: block;

	ui5-dialog {
		

		ui5-table {
			width: 300px;
			margin: 0 auto;
			margin-top: 10px;
			ui5-table-row {
				cursor: pointer;
			}
		}
		ui5-table-column::part(column) {
			text-align: center;
			height: 20px;
		}

		ui5-table-cell::part(cell) {
			text-align: center;
		}
	}
}