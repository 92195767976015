ivp-login {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;

	> div {
		position:relative;
		top: -50px;
		//@at-root: 1px solid #CCC;
		padding: 50px;
		display: flex;
		flex-direction: column;
		width: 80%;

		@media (min-width: 500px) {
			max-width: 300px;
		}

		field {
			display: flex;
			flex-direction: column;
			margin: 20px 0;
			width: 100%;

			> * {
				width: 100%;
				margin-bottom: 5px;
			}

			ui5-input {
				text-align: center;
			}
		}
	}
}