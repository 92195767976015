ivp-side-navigation {
	position: relative;
	display:block;	
	min-height: 100%;
	left:0;
	overflow: hidden;
	transition:  all 0.3s;	
	overflow-y: auto;
	min-width: 60%;
	
	@media(min-width: 500px) {
		min-width: 200px;
	}

	&.collapsed {
		left: -100%;
		width:0;
		min-width: 0;
	}

	> ul {
		border-right: 1px solid #CCC;	
	}

	ul {
		list-style:  none;
		padding: 0;
		margin: 0;
		background-color: white;
		color: #32363a;
		font-size: 16px;
		min-height: 100%;
		height: fit-content;

		li {			

			

			&.active {
				background-color: #e4f0fa;
			}

			div {				
				width: 100%;	
				padding: 0 20px;			
				padding-top: 20px;
				box-sizing: border-box;
				text-transform: uppercase;
				color: #436488b5;
				//color: white;
				line-height: 28px;	
				font-size: 14px;	
				border-bottom: 1px solid #CCC;	
				
				
			}

			a, a:visited, a:active, a:focus {
				display:flex;
				justify-items: center;				
				width: 100%;
				box-sizing: border-box;
				padding: 15px 20px;				
				text-decoration: none;
				color: #32363a;											
				border-bottom: 1px solid #CCC;
				

				ui5-icon {
					margin-right: 8px;
				}

				&[active] {
					background-color: #4fc6ff5e;
					&:hover {
						background-color: #4fc6ff5e;
					}
				}

				&:hover {
					background-color: #c6ecff5e;
				}
			}

			ul li a, a:visited, a:active, a:focus {
				padding-left: 25px;
			}
		}
	}	
}