@import "../defines.scss";

ui5-dialog {	
	.dialog-header {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		padding: 0 10px;		

		h1 {
			flex:1;
			padding: 0px 5px;
			text-align: center;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 100%;
			font-size: 18px;
			font-weight: 500;
			color: black;
		}	

		ui5-icon {
			cursor: pointer;
		}
	}

	.dialog-body {
		padding: 0;

		.row {
			width:100%;
			display: flex;
			flex-direction: column;	

			.col {
				width: 100%
			}					
		}

		@media (min-width: 1024px) {
			.row {
				flex-direction: row;
				
				.col {				
					width: calc(50% - 10px);
					&:first-child {
						margin-right: 20px;
					}
				}				
			}			
			
			
		}
	}

	.dialog-footer {
		height: 35px;
		padding: 5px 0;

		ui5-button {
			min-width: 105px;
		}
	}

	field {
		display: flex;
		flex-direction: column;
		padding-bottom: 25px;
		width: 100%;
		
		&.horizontal {
			flex-direction: row;
			align-items: center;
			justify-content: start;
			> * {
				width: fit-content;	
				max-width: 100%;
				
			}
		}		

		> * {
			width: 100%;	
			max-width: 100%;
			
		}
		


		span {
			color:rgb(106, 109, 112);
			font-size: 14px;
			font-weight: 400;			
		}

		select {
			
			padding: 8px;
			border: 1px solid #88919a;
			margin: 0;
			margin-right: 5px;	
			outline: none;
			border-radius: 2px;	
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			/* Some browsers will not display the caret when using calc, so we put the fallback first */ 
			background: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' height='24px'%3E%3Cpath fill='currentColor' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3E%3C/svg%3E");
			background: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' height='24px'%3E%3Cpath fill='currentColor' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3E%3C/svg%3E")
				white no-repeat calc(100% - 5px) !important; /* Better placement regardless of input width */			
			margin-bottom: 10px;
		}	
	
		figure, ivp-media-uploader {			
			border: 1px solid #CCC;
			background: #EFEFEF;
			margin: 0;
			padding: 0;
			width: 100%;
			height: 250px;

			@media(min-width: 1024px) {
				height: 300px;
			}

			img, video {
				width: 100%;
				height: 100%;
				object-fit: cover; 
			}
		}

		h2 {
			flex:1;
			padding: 0px;
			text-align: center;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 100%;
			font-size: 18px;
			font-weight: 500;
			color: black;
		}			
	}

	&.desktop {
		width: 512px;

		@media (min-width: 1024px) {
			width: 1024px;			
		}

		.dialog-body {			
			padding: 30px;							
		}

	}
}