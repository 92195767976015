@import "../defines.scss";

ivp-media-uploader {
	display: block;

	border:1px solid #CCC;
	background-color: #CCCCCC22;

	> div {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		flex-direction: column;
		cursor: pointer;

		ui5-icon {
			height: 50px;
			width: 50px;
			margin-bottom: 10px;
		}

		span {
			color: $text-color;
		}

		&:hover {
			ui5-icon, span {
				color: $highlighted;
			}
		}

		img {
			display: block;
			object-fit: cover;
			width: 100%;
			height: 100%;
			cursor: zoom-in;
		}

		div.remove {
			cursor:pointer;
			position: absolute;
			z-index: 1;
			color: white;
			top: 0px;
			right: 0px;
			display: flex;
			padding: 10px;
			background-color: #000000A0;
			border: 1px solid #FFFFFF22;
			justify-content: center;
			align-items: center;

			ui5-icon {
				height: 12px;
				width: 20px;
				color: white;
			}
		}
	}
}