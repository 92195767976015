ivp-context-menu {
	display:block; 

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			list-style-type: none;
			text-align: left;
			font-size:	15px;
			line-height: 35px;
			color: #212529;
			font-weight: 400;
			letter-spacing: 0.5px;
			padding: 0 20px;
			cursor: pointer;
			min-width: 150px;
			color: #555;

			&:hover {
				background-color: #c6ecff5e;
				color: #222;
			}

			&.separator {
				div.line {
					height: 1px;
					margin: 5px 0px;
					border-bottom: 1px solid #DFDFDF;
				}
			}
		}
	}
}
