ivp-sortable-list {
	display: block;
}

ul.sortable-list {
	list-style: none;
	border: 1px solid #88919a;
	padding: 0;
	margin: 0;
	border-radius: 3px;

	li {
		padding: 8px 4px;
		padding-right: 8px;
		border-bottom: 1px solid #88919a;
		display: flex;
		align-items: center;
		justify-content: center;
		

		&:last-child {
			border: none;
		}

		div {
			display: flex;
			align-items: center;
			flex:1;
			svg {
				height: 24px;
				color: gray;
				cursor: grab;
			}

			span {
				color: rgb(50, 54, 58);
				font-size: 14px;
				font-weight: 400;				
			}
		}	
		
		ui5-icon {
			cursor: pointer;
			&:hover {
				color: red;
			}
		}
	}

	.sortable-drag, .sortable-ghost {
		background-color: rgba(0, 153, 255, 0.22);
	}
}

