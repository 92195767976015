@import "../defines.scss";

.section {
	display:flex;
	flex-direction: column;
	width:100%;
	height: 100%;
	overflow: auto;	
	box-sizing: border-box;
	background-color: #EFEFEF;

	color:rgb(106, 109, 112);
	font-size: 14px;
	font-weight: 400;

	.card {		
		background-color: white;
		box-shadow: 1px 1px 1px #CCC;		
		margin: 0;

		@media (min-width: 500px) {
			border: 5px solid white;
			margin: 10px;
			
		}
		
		.card-heading {
			display:flex;
			padding: 10px 15px;			
			color: $text-color;	
			
			> div {
				flex:1; 
				display: flex;
				h3 {
					
					margin: 0;
					padding: 0;
				}
			}
		}
	
		@media (min-width: 1024px) {				
			margin: 10px;
			//margin-top: 0;
		}
	
		&.grow {
			flex-grow: 1;
			@media (min-width: 1024px) {		
				overflow-y:auto;
			}
		}
	
	}
}