@import "../defines.scss";

ivp-section-home {
	
	ui5-title {
		padding: 10px;
		padding-bottom:0;
	}

	nav {
		padding: 10px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		
		@media (min-width: 500px) {
			justify-content: start;
		}
			
		> a, >a:active, >a:visited {
			display: flex;
			text-align: center;
			flex-direction: column;
			padding: 10px;			
			align-items: center;
			justify-content: center;
			border: 1px solid #CCC;
			background-color: #FFF;
			width: 250px;
			cursor:pointer;
			margin: 5px;
			text-decoration: none;
			
			@media (min-width: 500px) {
				width: 150px;
			}

			&:hover {
				background-color: #f2fbff;
			}
			&:active {
				background-color: #4fc6ff5e;
			}

			ui5-icon {
				display: block;
				cursor: pointer;
				color: rgb(5,85,160);
				height: 32px;
				width: 32px;
				margin-bottom: 10px;
			}

			> div {			
				line-height: 1.25rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
				pointer-events: none;
				color: rgb(50,50,50);
				font-size: 14px;
				font-weight: 500;			
			}

			> p {			
				line-height: 1.25rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
				pointer-events: none;
				color: rgb(105,110,110);
				font-size: 12px;	
				margin: 0;
				padding: 0;	
			}		
		}
	}


}