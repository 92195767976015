ivp-widget-list {
	display: flex;
	flex-direction: column;

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 2px;

		.select {
			flex: 1;
			padding: 8px;
			border: 1px solid #88919a;
			margin: 0;
			margin-right: 5px;	
			outline: none;
			border-radius: 2px;	
		}
			
		select.select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			/* Some browsers will not display the caret when using calc, so we put the fallback first */ 
			background: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' height='24px'%3E%3Cpath fill='currentColor' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3E%3C/svg%3E");
			background: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' height='24px'%3E%3Cpath fill='currentColor' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3E%3C/svg%3E")
				white no-repeat calc(100% - 5px) !important; /* Better placement regardless of input width */
		}		
		
		ui5-select {
			flex: 1;
			margin-right: 5px;	
		}
	}
	
	ul.sortable-list {
		flex:1;		
		overflow-y: auto;
	}
	
}