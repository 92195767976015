@import "../defines.scss";


ivp-table {
	//position:relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	ui5-icon {
		cursor:pointer;

		&:hover {
			color: $highlighted;
		}
	}

	.card-heading {

		span {
			font-weight: 400;
		}

		> div {
			align-items: center;
			> div {
				display: flex;
				cursor:pointer;
				align-items:flex-start;

				&:hover {
					color: $highlighted;

					ui5-icon {
						color: $highlighted;
					}
				}	
				span {
					font-size: 14px;
				}			
			}
		}
	}

	.actions {
		display: flex;
		ui5-icon {
			margin: 3px;
		}
	}

	ui5-table-row.selectable {
		cursor: pointer;

		&:hover {			
			tr {
				background-color: beige;
			}
		}
	}

	ui5-table {
		flex: 1 2 1px; /* new */
	}

	nav {
		display: none;
		
	}
	
	&.show_nav {
		ui5-table {
			
		}
		nav {			
			display: flex;		
			position: absolute;	
			align-items: center;
			flex-wrap: wrap;
			background-color: #ffffff;
			border-top: 1px solid #354a5f;			
			box-sizing: border-box;

			bottom: 0;
			left: 0;
			width: 100%;
			justify-content: center;						
			
			
			@media(min-width: 500px) {
				width: 100%;
				justify-content: start;
				left: 10px;
				bottom: 10px;
				height: fit-content;
				width: calc(100% - 20px);
				background-color: #beeaff;
				border: 1px solid #87c3ff; 			
			}

			> * {
				margin: 10px;
				min-width: 200px;			
			}
	
			> h3 {
				color: #0754a0;
	
			}
		}
	
	}
}