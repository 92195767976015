labelling-dialog {
    .inline-loader {
        display: flex;
        flex-direction: column;
        height: 300px;
        justify-content: center;
        align-items: center;

        > div {
            margin-bottom: 10px;
        }
    }

    ui5-table-row {
        user-select: none;
    }

    .quantity {
        padding: 10px 5px;
        width: 50px;
        border-radius: 5px;
        border: 1px solid #ccc;
        text-align: right;
    }

    .pagination {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        ui5-link {
            padding: 10px;
            font-size: 15px;
        }
    }
}
