product-finalization-dialog {

	.row {
		&.form {
			.url ~ ui5-button {
				margin-top: 5px;
			}
		}
	}

	field {
		div.horizontal {
			display: flex;

			ui5-input {
				flex-grow: 1;
			}
		}

		&.NfcIndicator {
			flex-wrap: wrap;
			
			> div.error {
				flex-grow: 1;
    			flex-shrink: 0;
				color: var(--sapField_RequiredColor);
				font-size: 13px;
				margin-top: 5px;
				font-weight: bold;
			}
		}

		&.NfcInterface {
			.horizontal {
				margin-top: 5px;
			}

			ui5-button {
				margin-top: 5px;
			}
		}
	}
}